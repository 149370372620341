import moment from 'moment';
export function convertDateDDMMYYYY(originalDateString: string): string {
  const originalDate = new Date(originalDateString);

  const day = originalDate.getUTCDate();
  const month = originalDate.getUTCMonth() + 1; // Months are zero-based
  const year = originalDate.getUTCFullYear();

  const formattedDate = `${(day < 10 ? '0' : '') + day}-${
    (month < 10 ? '0' : '') + month
  }-${year}`;

  return formattedDate;
}

export function calculateStartAndEndTime(time: string, durationSlug?: string) {
  const startTime = moment(time, 'HH:mm:ss').format('HH:mm:ss');
  const endTime = moment(time, 'HH:mm:ss')
    .add(durationSlug, 'minutes')
    .format('HH:mm:ss');
  return { startTime, endTime };
}

export const format12 = (time) => {
  return moment(time, 'HH:mm:ss').format('hh:mm:ss A');
};
