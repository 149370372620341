import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface TicketObject {
  titleEn: string;
  titleAR: string;
  contentEn: string;
  contentAr: string;
  categoryId: string;
  id: string;
}

export interface TicketListInfo {
  page: number;
  messagePage: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  ticket: Array<TicketObject>;
  errors: unknown;
}

export interface TicketFilterInfo {
  qaustionType: string;
  typeId: string;
  quastionId: string;
}

@Module
export default class TicketModule extends VuexModule implements TicketListInfo {
  ticket = [] as Array<TicketObject>;
  allTicket = [] as Array<TicketObject>;
  ticketFilter = {} as TicketFilterInfo;
  page = 1;
  messagePage = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get ticket list
   * @returns Array<TicketObject>
   */
  get ticketList(): Array<TicketObject> {
    return this.ticket;
  }

  get allTicketList(): Array<TicketObject> {
    return this.allTicket;
  }

  /**
   * Get total items
   * @returns number
   */
  get ticketListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_TICKET](data) {
    this.ticket = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_TICKET](data) {
    this.allTicket = data.data;
  }

  @Mutation
  [Mutations.SET_TICKET_CURRENT_PAGE](page) {
    this.page = page;
  }
  @Mutation
  [Mutations.SET_TICKET_MESSAGE_CURRENT_PAGE](page) {
    this.messagePage = page;
  }

  @Mutation
  [Mutations.RESET_TICKET_LIST]() {
    this.ticket = [] as Array<TicketObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_TICKET_FILTER](obj) {
    this.ticketFilter.qaustionType = obj.qaustionType;
    this.ticketFilter.typeId = obj.typeId;
    this.ticketFilter.quastionId = obj.quastionId;
  }

  @Action
  [Actions.RESET_TICKET_STORE]() {
    this.context.commit(Mutations.RESET_TICKET_LIST);
  }

  @Action
  [Actions.UPDATE_TICKET_FILTER](val) {
    this.context.commit(Mutations.SET_TICKET_FILTER, val);
  }

  @Action
  [Actions.DELETE_TICKET](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/tickets', id)
      .then(() => {
        this.context.commit(Mutations.RESET_TICKET_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TICKET]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/tickets', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_TICKET_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TICKET_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_TICKET_CURRENT_PAGE, val);
  }
  @Action
  [Actions.UPDATE_TICKET_MESSAGE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_TICKET_MESSAGE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_TICKET](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/tickets', id);
  }

  @Action
  [Actions.GET_TICKETS_MESSAGES](id) {
    const params = {
      page: this.messagePage,
      take: 10,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/tickets/message/${id}`, { params })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
  @Action
  [Actions.CREATE_TICKETS_MESSAGES](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/tickets/message/${data.id}`, data.data)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_TICKETS]() {
    const params = {
      page: this.page,
      qaustionType: this.ticketFilter.qaustionType,
      typeId: this.ticketFilter.typeId,
      quastionId: this.ticketFilter.quastionId,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/tickets', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TICKET, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_ALL_TICKET]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/tickets/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_TICKET, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
  @Action
  [Actions.GET_ALL_STATIC_QUESTIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/settings/static-quastion/all`)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }
}
