import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LessonPendingObject {
  id: string;
  nameEn: string;
  nameAr: string;
  numberOfHorses: number;
}

export interface LessonPendingListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  LessonsPending: Array<LessonPendingObject>;
  errors: unknown;
}
export interface LessonPendingFilterInfo {
  customerId: string;
  lessonTypeId: string;
  classCategoryId: string;
  typeId: string;
  classLevelId: string;
  reaueststatusId: string;
  horseTypeId: string;
  startDate: string;
  endDate: string;
}

@Module
export default class LessonPendingModule
  extends VuexModule
  implements LessonPendingListInfo
{
  LessonsPending = [] as Array<LessonPendingObject>;
  lessonFilterPendingFilter = {} as LessonPendingFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get Arenas list
   * @returns Array<ArenasObject>
   */
  get lessonPendingList(): Array<LessonPendingObject> {
    return this.LessonsPending;
  }

  /**
   * Get total items
   * @returns number
   */
  get lessonPendingListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_LESSONS_PENDING](data) {
    this.LessonsPending = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_LESSONS_PENDING_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_LESSONS_PENDING_LIST]() {
    this.LessonsPending = [] as Array<LessonPendingObject>;
    this.lessonFilterPendingFilter = {} as LessonPendingFilterInfo;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_LESSONS_PENDING_FILTER](obj) {
    this.lessonFilterPendingFilter = obj;
  }

  @Action
  [Actions.RESET_LESSONS_PENDING_STORE]() {
    this.context.commit(Mutations.RESET_LESSONS_PENDING_LIST);
  }

  @Action
  [Actions.UPDATE_LESSONS_PENDING_FILTER](val) {
    this.context.commit(Mutations.SET_LESSONS_PENDING_FILTER, val);
  }

  @Action
  [Actions.DELETE_LESSONS_PENDING](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/lesson/pending-request', id)
      .then(() => {
        this.context.commit(Mutations.RESET_LESSONS_PENDING_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.GENERATE_LESSON_INVOICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/lesson/generate-payment-link/${id}`, id)
      .then(() => {
        this.context.commit(Mutations.RESET_LESSONS_PENDING_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_LESSONS_PENDING]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/lesson/pending-request', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_LESSONS_PENDING_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_LESSONS_PENDING_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_LESSONS_PENDING_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_LESSONS_PENDING_LIST]() {
    const params = {
      page: this.page,
      ...this.lessonFilterPendingFilter,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/lesson/pending-request', { params })
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_LESSONS_PENDING, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
