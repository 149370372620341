import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

@Module
export default class ReportsModule extends VuexModule {
  customersAttendance = [] as Array<any>;
  lessons = [] as Array<any>;
  horseLessons = [] as Array<any>;
  horseLessonsCount = [] as Array<any>;
  groomerLessonsCount = [] as Array<any>;
  customersBalance = [] as Array<any>;
  customersPayment = [] as Array<any>;
  customersStatment = [] as Array<any>;
  horseDailyLesson = [] as Array<any>;
  groomsDailyLesson = [] as Array<any>;
  arenaDailyLesson = [] as Array<any>;
  horseByGrp = [] as Array<any>;
  errors = {};

  /**
   * Get getCustomersAttendance list
   * @returns Array<any>
   */
  get getCustomersAttendance(): Array<any> {
    return this.customersAttendance;
  }

  /**
   * Get getlessons list
   * @returns Array<any>
   */
  get getLessons(): Array<any> {
    return this.lessons;
  }

  /**
   * Get getHorseLessons list
   * @returns Array<any>
   */
  get getHorseLessons(): Array<any> {
    return this.horseLessons;
  }

  /**
   * Get getHorseLessons list
   * @returns Array<any>
   */
  get getHorseLessonsCount(): Array<any> {
    return this.horseLessonsCount;
  }
  get getGroomerLessonsCount(): Array<any> {
    return this.groomerLessonsCount;
  }
  /**
   * Get getCustomersBalance list
   * @returns Array<any>
   */
  get getCustomersBalance(): Array<any> {
    return this.customersBalance;
  }

  /**
   * Get getCustomersPayment list
   * @returns Array<any>
   */
  get getCustomersPayment(): Array<any> {
    return this.customersPayment;
  }

  /**
   * Get getCustomersStatment list
   * @returns Array<any>
   */
  get getCustomersStatment(): Array<any> {
    return this.customersStatment;
  }
  /**
   * Get getHorseByGrp list
   * @returns Array<any>
   */
  get getHorseByGrp(): Array<any> {
    return this.horseByGrp;
  }

  /**
   * Get getHorseDailyLesson list
   * @returns Array<any>
   */
  get getHorseDailyLesson(): Array<any> {
    return this.horseDailyLesson;
  }
  get getGroomersDailyLesson(): Array<any> {
    return this.groomsDailyLesson;
  }

  get getArenaDailyLesson(): Array<any> {
    return this.arenaDailyLesson;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_ATTENDANCE](data) {
    this.customersAttendance = data;
  }
  @Mutation
  [Mutations.SET_HORSE_BY_GROUP](data) {
    this.horseByGrp = data;
  }
  @Mutation
  [Mutations.SET_LESSONS](data) {
    this.lessons = data;
  }

  @Mutation
  [Mutations.SET_HORSE_LESSONS](data) {
    this.horseLessons = data;
  }
  @Mutation
  [Mutations.SET_HORSE_LESSONS_COUNT](data) {
    this.horseLessonsCount = data;
  }
  @Mutation
  [Mutations.SET_GROOMER_LESSONS_COUNT](data) {
    this.groomerLessonsCount = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_BALANCE_REPORT](data) {
    this.customersBalance = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_PAYMENT_REPORT](data) {
    this.customersPayment = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_STATMENT_REPORT](data) {
    this.customersStatment = data;
  }

  @Mutation
  [Mutations.SET_HORSE_DAILY_LESSON](data) {
    this.horseDailyLesson = data;
  }

  @Mutation
  [Mutations.SET_GROOMS_DAILY_LESSON](data) {
    this.groomsDailyLesson = data;
  }
  @Mutation
  [Mutations.SET_ARENA_DAILY_LESSON](data) {
    this.arenaDailyLesson = data;
  }

  @Action
  [Actions.GET_CUSTOMER_ATTENDANCE](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      customerId: data.customerId ? data.customerId : undefined,
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      durationId: data.durationId ? data.durationId : undefined,
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      typeId: data.typeId ? data.typeId : undefined,
      dayId: data.dayId ? data.dayId : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
    };
    return ApiService.query('report/customer-attendance', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_ATTENDANCE, data);
      }
    );
  }
  @Action
  [Actions.GET_ALL_CUSTOMER_ACCOUNT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      customerId: data.customerId ? data.customerId : undefined,
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      durationId: data.durationId ? data.durationId : undefined,
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      typeId: data.typeId ? data.typeId : undefined,
      dayId: data.dayId ? data.dayId : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
    };
    return ApiService.query('report/customer-balance', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_ATTENDANCE, data);
      }
    );
  }

  @Action
  [Actions.GET_HORSE_BY_GROUP](data) {
    ApiService.setAuthorizationHeader();
    console.log(data);
    const params = {
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
      horseTypeId: data.horseTypeId ? data.horseTypeId : undefined,
    };
    return ApiService.query('report/group-by-horse', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_HORSE_BY_GROUP, data);
      }
    );
  }

  @Action
  [Actions.GET_LESSONS](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      customerId: data.customerId ? data.customerId : undefined,
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      durationId: data.durationId ? data.durationId : undefined,
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      typeId: data.typeId ? data.typeId : undefined,
      dayId: data.dayId ? data.dayId : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
    };
    return ApiService.query('report/lesson', { params }).then(({ data }) => {
      this.context.commit(Mutations.SET_LESSONS, data);
    });
  }
  @Action
  [Actions.GET_TRAINER_BY_GROUP](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      customerId: data.customerId ? data.customerId : undefined,
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      durationId: data.durationId ? data.durationId : undefined,
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      typeId: data.typeId ? data.typeId : undefined,
      dayId: data.dayId ? data.dayId : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
    };
    return ApiService.query('report/group-by-trainer', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_LESSONS, data);
      }
    );
  }
  @Action
  [Actions.GET_HORSE_LESSONS](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      customerId: data.customerId ? data.customerId : undefined,
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      durationId: data.durationId ? data.durationId : undefined,
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      typeId: data.typeId ? data.typeId : undefined,
      dayId: data.dayId ? data.dayId : undefined,
      groomerId: data.groomerId ? data.groomerId : undefined,
      arenaId: data.arenaId ? data.arenaId : undefined,
    };
    return ApiService.query('report/horse-lesson', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_HORSE_LESSONS, data);
      }
    );
  }
  @Action
  [Actions.GET_HORSE_LESSONS_COUNT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      horseTypeId: data.horseTypeId ? data.horseTypeId : undefined,
      year: data.year ? data.year : undefined,
      month: data.month ? data.month : undefined,
    };
    return ApiService.query('report/horse-lesson-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_HORSE_LESSONS_COUNT, data);
      }
    );
  }
  @Action
  [Actions.GET_GROOMER_LESSONS_COUNT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,

      year: data.year ? data.year : undefined,
      month: data.month ? data.month : undefined,
    };
    return ApiService.query('report/groomer-lesson-count', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_GROOMER_LESSONS_COUNT, data);
      }
    );
  }
  @Action
  [Actions.GET_CUSTOMER_BALANCE_REPORT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      attendanceId: data.attendanceId ? data.attendanceId : undefined,
      customerId: data.customerId ? data.customerId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
    };
    return ApiService.query('report/customer-balance', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_BALANCE_REPORT, data);
      }
    );
  }
  @Action
  [Actions.GET_CUSTOMER_PAYMENT_REPORT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      balanceFrom: data.balanceFrom ? data.balanceFrom : undefined,
      balanceTo: data.balanceTo ? data.balanceTo : undefined,
      customerId: data.customerId ? data.customerId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      paymentIndicatorId: data.paymentIndicatorId
        ? data.paymentIndicatorId
        : undefined,
    };
    return ApiService.query('finance/customer-payment-details/all', {
      params,
    }).then(({ data }) => {
      this.context.commit(Mutations.SET_CUSTOMER_PAYMENT_REPORT, data);
    });
  }

  @Action
  [Actions.GET_CUSTOMER_STATMENT_REPORT](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      balanceFrom: data.balanceFrom ? data.balanceFrom : undefined,
      balanceTo: data.balanceTo ? data.balanceTo : undefined,
      isPaid: data.isPaid ? data.isPaid : undefined,
      customerId: data.customerId ? data.customerId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
    };
    return ApiService.query('finance/customer-statment/all', {
      params,
    }).then(({ data }) => {
      this.context.commit(Mutations.SET_CUSTOMER_STATMENT_REPORT, data);
    });
  }
  @Action
  [Actions.GET_HORSE_DAILY_LESSON](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
      horseTypeId: data.horseTypeId ? data.horseTypeId : undefined,
    };
    return ApiService.query('report/horse-daily-lesson', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_HORSE_DAILY_LESSON, data);
      }
    );
  }
  @Action
  [Actions.GET_GROOMS_DAILY_LESSON](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
    };
    return ApiService.query('report/groomer-daily-lesson', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_GROOMS_DAILY_LESSON, data);
      }
    );
  }
  @Action
  [Actions.GET_ARENA_DAILY_LESSON](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      trainerId: data.trainerId ? data.trainerId : undefined,
      horseId: data.horseId ? data.horseId : undefined,
      startDate: data.startDate ? data.startDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
      startTime: data.startTime ? data.startTime : undefined,
      endTime: data.endTime ? data.endTime : undefined,
    };
    return ApiService.query('report/arena-daily-lesson', { params }).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_ARENA_DAILY_LESSON, data);
      }
    );
  }
}
